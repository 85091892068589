<template>
  <CRow>
    <vue-confirm-dialog></vue-confirm-dialog>

    <CCol>
      <CCol col="12" lg="6">
        <CCard>
          <CCardBody>
            <CInput
              id="Name"
              label="名前"
              placeholder="Name"
              v-model="user.name"
              :invalid-feedback="errors.name"
              :is-valid="errors.name ? false : undefined"
            >
            </CInput>
            <CInput
              id="Email"
              label="Email"
              placeholder="Email"
              v-model="user.email"
              :invalid-feedback="errors.email"
              :is-valid="errors.email ? false : undefined"
            >
            </CInput>
            <CInput
              id="Password"
              label="パスワード"
              v-model="user.password"
              placeholder="Password"
              type="password"
              :invalid-feedback="errors.password"
              :is-valid="errors.password ? false : undefined"
            >
            </CInput>
            <div style="margin-bottom: 0.5rem;">店舗</div>
            <CSelect
              id="Shop"
              horizontal
              v-model="user.shop_id"
              :options="shopOptions"
              @change="selectShopChange"
              placeholder="選択する"
              :invalid-feedback="errors.shop_id"
              :is-valid="errors.shop_id ? false : undefined"
            />
          </CCardBody>
          <CCardFooter>
            <CButton class="mr-3" color="primary" @click="goBack">戻る</CButton>
            <CButton color="primary" @click="handleConfirm" >{{$route.params.id ? '更新する' : '追加する'}}</CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CCol>
  </CRow>
</template>

<script>
import ResourceApi from "@/api/resourceApi";
import Vue from "vue";
import {SCREEN_KEY} from "@/utils/constance";

const apiUsers = new ResourceApi('users');
const apiShop = new ResourceApi('shops');

export default {
  name: 'User',
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.usersOpened = from.fullPath.includes('users')
    })
  },
  data () {
    return {
      errors: {},
      updateApi: Function,
      user: {
        email: '',
        id: this.$route.params.id,
        name: '',
        password: '',
        shop_id: '',
      },
      shopOptions: [],
    }
  },
  created() {
    this.userData();
    this.getShops();
  },
  methods: {
    goBack() {
      this.usersOpened ? this.$router.go(-1) : this.$router.push({path: '/users'})
    },
    selectShopChange (val) {
      this.user.shop_id =  val.target.value  ? parseInt(val.target.value) : ''
    },

    handleConfirm(){
      const id = this.$route.params.id
      this.$confirm(
          {
            title: '確認',
            message: id ? 'ユーザー情報を更新します。宜しいでしょうか？' : 'ユーザー情報を追加します。宜しいでしょうか？',
            button: {
              no: 'いいえ',
              yes: 'はい'
            },
            callback: confirm => {
              if (confirm) {
                // ... do something
                this.handleUpdateOrCreateUser()
              }
            }
          }
      )
    },
    handleUpdateOrCreateUser() {
      this.loading = true
      let param = this.user;
      this.confirmModal = true;
      const id = this.$route.params.id
      this.updateApi = apiUsers.create;
      if(id){
        this.updateApi = apiUsers.edit;
      }
      this.updateApi(param).then(response => {
        Vue.$toast.success('登録成功しました。')
        setTimeout(this.goBack(), 2000);
      }).catch(error => {
        this.errors =  error.response?.data?.errors;
      }).finally(() =>{
        this.loading = false
      })
    },
    getShops () {
      // this.resetSelected()
      this.loading = true
      apiShop.all({screen_key : SCREEN_KEY.LIST_USERS}).then(response => {
        // console.log(response.data)
        let shops = [];
        response.data.data.map(s =>{
          shops.push({label: s.name, value: parseInt(s.id)})
        })
        this.shopOptions = shops;
        this.loading = false
      }).catch(error => {
        this.loading = false
      })
    },
    userData () {
      const id = this.$route.params.id
      if(!id){
        return
      }
      apiUsers.show({id: id}).then(response => {
        // console.log(response.data)
        this.user = response.data;
        this.user.shop_id = parseInt(this.user.shop_id)
        this.user.id = this.$route.params.id
        this.loading = false
      }).catch(error => {
        this.loading = false
      })
    },
  }
}
</script>
